
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from '../core/api/api.service';
import { Observable } from 'rxjs';
import { PartnersBackend } from './partners.backend';
import { TasksBackend } from './tasks.backend';
import { getStateBitMask } from '../core/helpers';

@Injectable()
export class MarketingCampaignBackend {
  constructor(private _api: ApiService, private partnersBackend: PartnersBackend, private tasksBackend: TasksBackend) {
  }

  getMarketingCampaignsList(params): Observable<any[]> {
    const pars: any = {};
    pars.dateTo = params.to;
    pars.dateFrom = params.from;
    pars.startFrom = params.startFrom;
    pars.pageLength = params.pageLength;
    pars.orderBy = params.orderBy;
    pars.orderDir = params.orderDir;
    params.executors ? pars.executorStr = params.executors : null;
    params.search ? pars.filter = params.search : null;
    // pars.statesBitmask = getStateBitMask(params);
    // pars.clientTypeIdStr = params.clientTypesList;
    // pars.sourceIdStr = params.sourcesList;
    // pars.taskTypeId = params.taskTypeId;
    params.products ? pars.productStr = params.products : null;
    return <any>this._api.getAuth('tasks/campaign', pars).pipe(map((res: any) => res.body));
  }

  getMarketingCampaign(id) {
    // let httpParams = new HttpParams();
    // httpParams.append('campaignsId', id);
    return <any>this._api.getAuth(`tasks/campaign`, { campaignsId: id }).pipe(map((res: any) => res.body));
  }

  editMarketingCampaign(marketingCampaign) {
    return <any>this._api.postAuth('tasks/campaign', marketingCampaign).pipe(map((res: any) => res.body));
  }

  // createMarketingCampaign(marketingCampaign) {
  //   return <any>this._api.postAuth('marketingCampaigns/create', marketingCampaign).pipe(map((res: any) => res.body));
  // }

  getProducts() {
    return this.partnersBackend.getRequestProductsByPartner(134018);
  }

  getMarketingSourcesList() {
    return <any>this._api.getAuth(`projects/types/1`).pipe(map((res: any) => res.body.entities));
  }

  getMarketingClientTypesList() {
    // K_Tipas
    return <any>this._api.getAuth(`projects/types/2`).pipe(map((res: any) => res.body.entities));
  }

  getAllMarketingTasks(params: any) {
    const pars: any = {};
    pars.dateTo = params.to;
    pars.dateFrom = params.from;
    pars.startFrom = params.startFrom;
    pars.pageLength = params.pageLength;
    pars.orderBy = params.orderBy;
    pars.orderDir = params.orderDir;
    params.search ? pars.filter = params.search : null;
    pars.statesBitmask = getStateBitMask(params);
    pars.clientTypeIdStr = params.clientTypesList;
    pars.sourceIdStr = params.sourcesList;
    // pars.taskTypeId = params.taskTypeId;
    pars.taskTypeStr = params.taskTypeStr;
    pars.executorStr = params.executors;
    pars.productStr = params.products;
    // pars.campaignsId = null;
    return <any>this._api.getAuth(`tasks/campaign/task`, pars).pipe(map((res: any) => res.body));
  }

  getCampaignsMarketingTasks(id, params: any) {
    const pars: any = {};
    pars.dateTo = params.to;
    pars.campaignsId = id;
    pars.dateFrom = params.from;
    pars.startFrom = params.startFrom;
    pars.pageLength = params.pageLength;
    pars.orderBy = params.orderBy;
    pars.orderDir = params.orderDir;
    params.search ? pars.filter = params.search : null;
    pars.statesBitmask = getStateBitMask(params);
    pars.clientTypeIdStr = params.clientTypesList;
    pars.sourceIdStr = params.sourcesList;
    // pars.taskTypeId = params.taskTypeId;
    pars.taskTypeStr = params.taskTypeStr;
    pars.executorStr = params.executors;
    pars.productStr = params.products;
    // params.partnerId !== undefined ? pars.partnerId = params.partnerId : null;
    // params.userId !== undefined ? pars.userId = params.userId : null;
    return <any>this._api.getAuth(`tasks/campaign/task`, pars).pipe(map((res: any) => res.body));
  }

  deleteCampaignsTasks(campaignsId) {
    return <any>this._api.deleteAuth(`tasks/campaign/${campaignsId}/task`).pipe(map((res: any) => res.body));
  }

  getCampaignsParticipantList(id) {
    return <any>this._api.getAuth(`tasks/campaign/${id}/participants`).pipe(map((res: any) => res.body.entities));
  }

}